import React from "react";
import styled from "styled-components";

const FooterStyles = styled.footer`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    margin-top: auto;
    text-align: center;
    color: #6f6f6f;
    font-weight: 300;
`;

const Footer = () => {
    return (
        <FooterStyles>
            © {new Date().getFullYear()} NOWU | Wszelkie prawa zastrzeżone.
        </FooterStyles>
    );
};

export default Footer;