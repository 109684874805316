import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import SVGPhone from "../assets/images/icon-phone.svg";
import SVGLogo from "../assets/images/nowu-biuro-nieruchomosci-kalisz-logo.svg";

const HeaderStyles = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 120px;
  width: 1280px;
  margin: 0 auto;

  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 60px;
  }

  @media (max-width: 992px) {
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 20px;
    height: auto;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const MenuStyles = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  color: #000;

  @media (max-width: 992px) {
    padding: 20px 0;
  }
`;

const MenuItem = styled.li`
  font-weight: 600;
  transition: color 0.2s ease-in-out;

  @media (max-width: 992px) {
      font-size: 14px;
    }

  &:nth-child(2) {
    margin: 0 40px;

    @media (max-width: 992px) {
      margin: 0 20px;
    }
  }

  &:hover {
    color: #8E2315;
  }
`;

const InfoStyles = styled.div`
  display: flex;
  align-items: center;
  color: #fff;

  p {
    margin-right: 16px;
    color: #6f6f6f;
  }
`;


const ButtonStyles = styled.a`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: #8E2315;
  color: #fff;
  font-weight: 600;
  border-radius: 40px;
  font-size: 14px;

  @media (max-width: 992px) {
    padding: 10px;
  }

  svg {
    margin-right: 8px;
  }
`;

const Navbar = () => (
  <HeaderStyles>
    <Link to="/" title="Nowu nieruchomości logo"><SVGLogo /></Link>
    <nav>
      <MenuStyles>
        <MenuItem><a href="https://nieruchomoscikalisz.com.pl">Nieruchomości</a></MenuItem>
        <MenuItem><a href="http://nieruchomoscikalisz.com.pl/oferta/">Ubezpieczenia</a></MenuItem>
        <MenuItem><a href="https://odszkodowaniakalisz.com.pl">Odszkodowania</a></MenuItem>
      </MenuStyles>
    </nav>
    <InfoStyles>
      <p>Masz pytanie?</p>
      <ButtonStyles href="tel:+48694505803"><SVGPhone /> +48 694 505 803</ButtonStyles>
    </InfoStyles>
  </HeaderStyles>
)

export default Navbar;
